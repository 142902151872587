import React from 'react';
import './Player.css'

class Player extends React.Component {
  render() {
    if (! this.props.mediaBlob) {
      return null;
    }

    return (
      <video ref={this.props.playerRef}
        src={URL.createObjectURL(this.props.mediaBlob)}
        autoPlay
        controls
      />
    );
  }
}

export default Player;