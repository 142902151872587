import React from 'react';
import Button from '../Button/Button'
import ButtonIcon from '../ButtonIcon/ButtonIcon'
import Select from '../Select/Select'
import DownloadLink from '../DownloadLink/DownloadLink'

import microphone from '../../assets/images/microphone.png';
import microphoneActive from '../../assets/images/microphone-active.png';

import './Panel.css'

class TopPanel extends React.Component {
  constructor(props){
    super(props);
    this.toggleMicrophone = this.toggleMicrophone.bind(this);
    this.updateParent = this.updateParent.bind(this);
    
    this.state = {
      microphone: this.props.settings.default.microphone,
      startIsHighlighted: false,
    }
 
  }

  toggleMicrophone() {
    const currentState = this.state.microphone;
    this.setState({ microphone: !currentState }, this.updateParent);
  }

  updateParent() {
    this.props.settings.handleToUpdateSettings(this.state)
  }

  componentDidMount() {
    this.setState({startIsHighlighted: true});
    setTimeout(() => this.setState({startIsHighlighted: false}), 1500);
  }

  render() {
    return (
      <div className={`group ${this.props.settings.handleToRecord.status} ${this.state.startIsHighlighted ? 'coachmark-overlay is-highlight-start' : ''}`}>
          <div>
           <h1> {this.state.startIsHighlighted} </h1>
              <p>Record Mic Sound</p>
              <ButtonIcon 
                className={this.state.microphone ? 'active' : '' } 
                onPress={this.toggleMicrophone} 
              >
                <img src={this.state.microphone ? microphoneActive : microphone } alt="Microphone" />
              </ButtonIcon>
          </div>
          
          {this.props.settings.handleToRecord.status !== 'recording' && (
            <Button text="Start Recording" clickHandler={async () => {
              this.props.settings.handleToRecord.startRecording();
            }}/>
          )}
          {this.props.settings.handleToRecord.status === 'recording' && (
            <Button text="Stop Recording" styleName={'active'} clickHandler={this.props.stopRecordingHandler} />
          )}
      </div>
    )
  }
}

class BottomPanel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      inputValue: '',
      selectValue: null
    }
  }

  handleInputChange (e) {
    let newValue = e.target.value
    this.setState({inputValue: newValue})
  }

  handleSelectChange (newValue) {
    this.setState({selectValue: newValue})
  }

  render() {
    let buttonStyle = this.props.mediaBlob ? 'purple' : 'disabled';
    
    return (
      <div className={`group ${this.props.mediaBlob ? 'purple' : ''} ${this.props.downloadIsHighlighted ? 'coachmark-overlay is-highlight-download' : ''}`}>
        <p>Save Your Record</p>
        <input class="btn-like" id="custom_name" type="text" placeholder="Record Name"  onChange={this.handleInputChange.bind(this)}  value={this.state.inputValue} />
        <Select handleSelectChange={this.handleSelectChange.bind(this)} />
        <DownloadLink 
          customName={this.state.inputValue} 
          customFormat={this.state.selectValue} 
          className={buttonStyle} 
          mediaBlob={this.props.mediaBlob}
          playerRef={this.props.playerRef}
        />
    </div>
    )
  }
}
class Panel extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      downloadIsHighlighted: false,
    }
    this.stopRecording = this.stopRecording.bind(this);
  }

  stopRecording() {
    this.setState({downloadIsHighlighted: true});
    setTimeout(() => this.setState({downloadIsHighlighted: false}), 1500);
    this.props.handleToRecord.stopRecording();
  }

  render() {
    return (
      <div className="main__sidebar">
        <TopPanel settings={this.props} stopRecordingHandler={this.stopRecording} />
        <BottomPanel mediaBlob={this.props.handleToRecord.mediaBlob} downloadIsHighlighted={this.state.downloadIsHighlighted} playerRef={this.props.handleToRecord.playerRef} />
      </div>
    );
  }
}

export default Panel;