import './Ads.css'

const links = [
  { title: 'Online Screen Recorder'},
  { title: 'User Screen Recording' },
  { title: 'Record Screen Loom'},
  { title: 'Twilio Screen Recording'},
  { title: 'Screen Recorder Online'}]
function Ads() {

  return (
    <div class="ads-wrapper">
      <div class="container">
        <div class="content">
          <p class="ads">
            Ads:
          </p>
          {
            links.map(link => {
              return <a className={"ads-link"}
                href={'https://searc.me/03289e8f-6d50-4ad1-806a-2a0708038a5d?q=' + link.title.toLowerCase().split(' ').join('-') + '&chname=cel_screens'}
                target="_blank">
                { link.title }
              </a>
            })
          }

        </div>
      </div>
    </div>
  );
}

export default Ads;
