import React from 'react';
import './ButtonIcon.css'

class ButtonIcon extends React.Component {
  render() {
    const { onPress, className, children } = this.props;

    return (
      <button className ={`btn__source btn-flex btn-like ${className}`} onClick={onPress}>
        <span className="btn__icon">
            {children}
            <span className="switch"></span>
        </span>
    </button>
    );
  }
}

export default ButtonIcon;