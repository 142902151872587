import React from 'react';
import './DownloadLink.css'
import VideoConverter from 'convert-video';
import TagManager from 'react-gtm-module'

class DownloadLink extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bttnTitle: 'Download'
        }
    }
    
    getRandomString(length) {
        var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var result = '';
        for ( var i = 0; i < length; i++ ) {
            result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
        }
        return result;
    }

    download = async() => {
        const fileName = this.props.customName || `video_${this.getRandomString(10)}`
            const format = this.props.customFormat.toLowerCase() || 'mp4';
            const sourceName = 'input.webm'
            const outFile = `output.${format}`
            this.setState({bttnTitle: 'Downloading...'});
            const options = []

            TagManager.dataLayer({
                dataLayer: {
                    event: "ga_event",
                    eventCategory: 'click',
                    eventAction: 'download'
                }
            });

            const src = this.props.playerRef.current.src;
            const data = await fetch(src);
            const blob = await data.blob();
            blob.name = sourceName;
            const converted = await VideoConverter.convert(blob, format);
            const a = document.createElement("a");
            a.download = `${fileName}.${format}`;
            a.href = converted.data;
            a.click();
            this.setState({bttnTitle: 'Download'});
    }

    render() {
        const newClass =(this.state.bttnTitle === 'Downloading...') ? false : true;
        return (
            <button className={`btn-like btn ${(newClass) ? this.props.className : 'disabled'}`}  onClick={this.download.bind(this)}>{this.state.bttnTitle}</button>
        );
    }
}

export default DownloadLink;