import React, {useState} from 'react';
import './Screen.css'
import check from '../../assets/images/check.png'

function Screen(props) {
  const [value, updateValue] = useState('');
  function updateValueE(v) {
    updateValue(v);
  }
  return (
    <div className={`main__screen ${props.status}`}>
       { props.status === 'recording' && (<p>Recording in progress...</p>)}
       { props.status === 'stopped' && props.mediaBlob && (<div class="done"><img src={check} /> <p>Done</p></div>)}
      <div className={`main__screen-inner ${props.status}`}>
        {props.children}
      </div>
      { props.status === 'recording' && (<Timer updateValueE={updateValueE}  status={props.status} />)}
       { props.status === 'stopped' && props.mediaBlob &&  (<p class="total"> Total: {value} </p>) }
    </div>
  );
}
class Timer extends React.Component {
  timeObject = {
    hours: 0,
    mins: 0,
    seconds: 0,
    showHours: '00:',
    showMinutes: '00:',
    showSeconds: '00',
  }
  stop = false;
  constructor(props) {
    super(props)
    this.state = {
      timeString: ''
    }
   this.startTimer = this.startTimer.bind(this);
  }

  componentDidMount() {
    this.startTimer();
  }

  componentWillUnmount() {
    this.stop = true;
    this.props.updateValueE(this.state.timeString)
  }

   startTimer() {
    const timeObject = this.timeObject;
    const timeString = timeObject.showHours + timeObject.showMinutes + timeObject.showSeconds;
    this.setState({timeString});
    setTimeout( () => {
      timeObject.seconds++;
      if (timeObject.seconds > 59) {
        timeObject.seconds = 0; timeObject.mins++;
        if (timeObject.mins > 59) {
          timeObject.mins = 0; timeObject.hours++;
          if (timeObject.hours < 10) {
            timeObject.showHours = '0' + timeObject.hours + ':'
          } else { timeObject.showHours = timeObject.hours + ':' }

        }

        if (timeObject.mins < 10) {
          timeObject.showMinutes = '0' + timeObject.mins + ':'
        } else {
          timeObject.showMinutes = timeObject.mins + ':'
        }
        
      }
      if (timeObject.seconds < 10) {
        timeObject.showSeconds = '0' + timeObject.seconds;
      } else {
        timeObject.showSeconds = '' + timeObject.seconds;
      }
      if (!this.stop) {
        this.startTimer();
      }
     
    }, 1000);
  }

  render() {
    return (
      <div className="timer">
       <span class="dot"></span> {this.state.timeString}
      </div>
    );
  }

}
export default Screen;