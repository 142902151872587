import './Header.css'
import logo from '../../assets/icons/48.png';

function Header() {
  return (
    <div class="header-wrapper">
      <div class="container">
        <header class="header">
          <div class="header-left">
            <img src={logo} width="45" />
            <h2>
              Screens Recorder
            </h2>
          </div>
          <p class="header-right">
            Record Your Screen For Free
          </p>
        </header>
      </div>
    </div>
  );
}

export default Header;
