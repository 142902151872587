import React, { createRef, useState } from 'react';
import useMediaRecorder from '../../libs/MediaRecorder' //'@wmik/use-media-recorder';

import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import Ads from '../Ads/Ads'
import Advantages from '../Advantages/Advantages'
import Screen from '../Screen/Screen'
import Panel from '../Panel/Panel'
import Player from '../Player/Player'
import LiveStreamPreview from '../LiveStreamPreview/LiveStreamPreview'
import Message from '../Message/Message'
import TagManager from 'react-gtm-module'

import './App.css';

import microphoneImg from '../../assets/images/microphone-dark.png';
import breakImg from '../../assets/images/break.png';
import Adsense from '../Adsense/Adsense';

function App() {
  let [audio, setAudio] = React.useState(false);
  let [showError, setShowError] = React.useState(false);
  let [permissionMicrophone, setPermissionMicrophone] = React.useState(null);
  let playerRef = createRef();

 
  let {
    error,
    status,
    liveStream,
    mediaBlob,
    stopRecording,
    startRecording,
    clearMediaStream
  } = useMediaRecorder({
    recordScreen: true,
    blobOptions: { mimeType: 'video/webm' },
    mediaStreamConstraints: { audio, video: true }
  });

  navigator.permissions.query({name:'microphone'}).then(function(result) {
    if(result.state === 'granted') {
      setPermissionMicrophone(true)
    } else {
      setPermissionMicrophone(false)
    }
  });
  
  //eslint-disable-next-line
  React.useEffect(() => clearMediaStream, []);

  let defaultSettings = {
    microphone: audio
  };

  let handleToRecord = {
    stopRecording: customStopRecording,
    startRecording: customStartRecording,
    mediaBlob: mediaBlob,
    playerRef:playerRef,
    status: status
  }

  function customStartRecording() {
    clearMediaStream()
    if(!(audio === true && permissionMicrophone === false)) {
      startRecording();
      TagManager.dataLayer({
        dataLayer: {
          event: "ga_event",
          eventCategory: 'click',
          eventAction: 'start'
        }
      });
    } else {
      navigator.mediaDevices.getUserMedia({audio: true, video: false})
      .then((stream) => {
        startRecording();
      })
      .catch((err) => {
        setShowError(true)
      });
    }
  }

  function customStopRecording() {
    stopRecording();
    TagManager.dataLayer({
      dataLayer: {
        event: "ga_event",
        eventCategory: 'click',
        eventAction: 'stop'
      }
    });
  }

 

  function closeErrorMessage() {
    setShowError(false)
  }

  function handleToUpdateSettings(value) {
    console.log(value)
    setAudio(value.microphone)
  }

  return (
    <div className="App">
      <Header />
      <section className="main">
        <div className="container">
        <Adsense adsense={1} />
            <div className="row">
                <Screen status={status} mediaBlob={mediaBlob}>
                  {(status === 'idle' || status === 'failed' || status === 'acquiring_media') && (<p>Click on Start Recording</p>)}
                  <LiveStreamPreview stream={liveStream} />
                  <Player mediaBlob={mediaBlob} playerRef={playerRef}/>
                </Screen>
                <Panel default={defaultSettings} handleToUpdateSettings={handleToUpdateSettings} handleToRecord={handleToRecord} />

            </div>
        </div>
      </section>
      <Advantages />
      <Footer />
      
      {showError && audio && ! permissionMicrophone &&
          (<Message onClose={closeErrorMessage} type="microphone"><img src={microphoneImg} alt="Microphone" /></Message>)}

      {error && error.message !== "Permission denied" && status === "failed" && showError && 
                  (<Message onClose={closeErrorMessage} type="error" mssg={error.message}><img src={breakImg} alt="Error" /></Message>)}

    </div>
  );
}

export default App;
