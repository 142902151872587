import './Footer.css'

function Footer() {
    function createLink(pageName) {
        if (window.location.host.includes('dev')) {
            return `https://www.dev.screensrecorder.com/${pageName}`
        } else {
            return `https://www.screensrecorder.com/${pageName}`;
        }
    }
    return (
        <div class="footer-wrapper">
            <div class="container">
                <footer class="footer">
                    <div class="links">
                        <a href={createLink('privacy')}>
                            Privacy
                        </a> 
                        <a href={createLink('eula')}>
                            EULA
                        </a>
                        <a href={createLink('contact')}>
                            Contact
                        </a>
                        <a href={createLink('blog')}>
                            Blog
                        </a>
                    </div>
                    <p>
                        © Screens Recorder, 2015–2022
                    </p>
                    <p>

                    </p>
                </footer>
            </div>
        </div>
    );
}

export default Footer;
