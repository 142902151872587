import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App/App';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-WFJHRQX'
}
TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
