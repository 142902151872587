import './Message.css'
import Button from '../Button/Button'

const default_mssgs = {
  'webcam': 'Webcam is not supported',
  'microphone': 'Microphone is not supported',
  // 'warning': 'Recording didn’t success for any reason',
  // 'break': 'Download didn’t success for any reason',
  'error': `Oops! Something went wrong. Please try again`
}
  

function Message(props) {
  const { type, mssg, children, onClose } = props;

  return (
    <div className="modal show">
        <div className="modal-container">
            <div>
                {children}
            </div>
            <p>{mssg || default_mssgs[type]}</p>
            <Button text="Close" clickHandler={onClose}/>
        </div>
    </div>
  );
}

export default Message;




        
            