import React, { Component } from 'react';
import './Adsense.css';
class Adsense extends Component {

    componentDidMount() {
        (window.adsbygoogle = window.adsbygoogle || []).push({})
    }

    render() {
        return (
            <div className={`banner`}>
             {this.props.adsense === 1 && (
               <ins class="adsbygoogle adsense-horizontal-unit"
               style={{display:"block",width:"728px", height:"90px"}}
               data-ad-client="ca-pub-6495373596935145"
               data-ad-slot={5686388016}
               data-full-width-responsive="true"></ins>
            )}
            
            {this.props.adsense === 2 && (
               <ins class="adsbygoogle"
               style={{display:"block",width:"250px", height:"500px"}}
               data-ad-client="ca-pub-6495373596935145"
               data-ad-slot={6807488561}
               data-full-width-responsive="true"></ins>
            )}

            {this.props.adsense === 3 && (
                <ins class="adsbygoogle"
                style={{display:"block",width:"250px", height:"500px"}}
                data-ad-client="ca-pub-6495373596935145"
                data-ad-slot={4998786717}
                data-full-width-responsive="true"></ins>
            )}
            </div>
            ) 
    }
}

export default Adsense;