import './Advantages.css'
import advantagePic1 from '../../assets/images/advantages1.png';
import advantagePic2 from '../../assets/images/advantages2.png';
import advantagePic3 from '../../assets/images/advantages3.png';
import Adsense from '../Adsense/Adsense';
function Advantages() {

  return (
    <div class="advantages-wrapper">

      <div class="container">
        <h2 class="main-title">
          Free Online Screen Recorder
        </h2>
        <p class="main-description">
          Start your unlimited recordings with a single click!
        </p>

        <div class="adsense-wrapper">
          <div class="adsense-unit">
            <Adsense adsense={3} />
          </div>
          <div class="advantages-container">

            <div class="advantages-row ">

              <div class="advantage-text">
                <h3>
                  Web-based Screen Recorder
                </h3>
                <p>
                  You can easily capture your screen online with our free screen recordering tool.
                </p>
              </div>
              <img src={advantagePic1} alt="" />

            </div>

            <div class="advantages-row">
              <div class="advantage-text">
                <h3>
                  Guaranteed security
                </h3>
                <p>
                  Don't be concerned about your privacy or security because we have no access to the files you've uploaded.
                </p>
              </div>
              <img src={advantagePic2} alt="" />

            </div>

            <div class="advantages-row ">
              <div class="advantage-text">
                <h3>
                  Free to Use
                </h3>
                <p>
                  The screen recording software is free and easy to use. Don't hesitate to record your content now.
                </p>
              </div>
              <img src={advantagePic3} alt="" />

            </div>
          </div>
          <div class="adsense-unit">
            <Adsense adsense={2} />
          </div>
        </div>

      </div>

    </div>
  );
}

export default Advantages;
