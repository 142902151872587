import React from 'react';
import './LiveStreamPreview.css'

function LiveStreamPreview({ stream }) {
    let videoPreviewRef = React.useRef();
  
    React.useEffect(() => {
      if (videoPreviewRef.current && stream) {
        videoPreviewRef.current.srcObject = stream;
      }
    }, [stream]);
  
    if (!stream) {
      return null;
    }
  
    return (
        <video 
            ref={videoPreviewRef} 
            autoPlay 
        />
    );
}

export default LiveStreamPreview;